// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/sections/business-banner/TopBusinessSlider.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/sections/business-banner/TopBusinessSlider.tsx");
}
// REMIX HMR END

import { getMediaPath, getProfilePhotoPath } from "~/utils/helper";
import "./businessSection.css?__remix_sideEffect__";
import { Link } from "@remix-run/react";
import Slider from "react-slick";
export default function TopBusinesses({
  section_title,
  businesses
}) {
  const totalBusinesses = businesses.length;
  const sliderSettings = {
    dots: false,
    // infinite: totalBusinesses >= 4,
    infinite: true,
    speed: 500,
    slidesToShow: totalBusinesses >= 4 ? 4 : totalBusinesses,
    slidesToScroll: 1,
    centerMode: totalBusinesses >= 4,
    centerPadding: totalBusinesses >= 4 ? "20px" : "0px",
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: Math.min(totalBusinesses, 2)
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: Math.min(totalBusinesses, 1)
      }
    }]
  };
  return <section className="top_business_section">
      <div className="container">
        <h2 className="heading">{section_title}</h2>
        <Slider {...sliderSettings} className="need_slider">
          {businesses.slice(0, 5).map(business => <BusinessCard key={business.id} business={business} />)}
        </Slider>
        <div className="see-all-container">
          <Link to="/view/business" className="see-all-btn">
            See All
          </Link>
          {/* <Link to="/view/business" className="c_link">
             Advertise With Us
             <div className="icon_box">
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="26"
                 height="8"
                 viewBox="0 0 26 8"
                 fill="none"
               >
                 <path
                   d="M25.6036 4.10355C25.7988 3.90829 25.7988 3.59171 25.6036 3.39645L22.4216 0.214466C22.2263 0.0192039 21.9097 0.0192039 21.7145 0.214466C21.5192 0.409728 21.5192 0.726311 21.7145 0.921573L24.5429 3.75L21.7145 6.57843C21.5192 6.77369 21.5192 7.09027 21.7145 7.28553C21.9097 7.4808 22.2263 7.4808 22.4216 7.28553L25.6036 4.10355ZM0.25 4.25H25.25V3.25H0.25V4.25Z"
                   fill="white"
                 />
               </svg>
             </div>
            </Link> */}
        </div>
      </div>
    </section>;
}
_c = TopBusinesses;
function BusinessCard({
  business
}) {
  const truncatedName = business.name.length > 20 ? business.name.substring(0, 20) + "..." : business.name;
  return <div className="swiper-slide">
      <div className="business-card">
        <Link to={`/business-details/${business.id}`} className="card-link">
          <div className="logo-container">
            <img src={business.logo ? getProfilePhotoPath(business.logo) : getMediaPath("/avatars/blank-logo.png")} alt={business.name} className="business-logo" />

          </div>
          <h3 className="business-name">{truncatedName}</h3>
          <p className="total-sales">Total Sales: $2000</p>
        </Link>
      </div>
    </div>;
}
_c2 = BusinessCard;
var _c, _c2;
$RefreshReg$(_c, "TopBusinesses");
$RefreshReg$(_c2, "BusinessCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;
// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/sections/list-slider/ListSlider.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/sections/list-slider/ListSlider.tsx");
}
// REMIX HMR END

import { useEffect, useState } from "react";
import Slider from "react-slick";
import "./listslider.css?__remix_sideEffect__";
import { createPublicUrl } from "~/utils/helper";
const sliderSettings = {
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 1,
  responsive: [{
    breakpoint: 1200,
    settings: {
      slidesToShow: 6
    }
  }, {
    breakpoint: 992,
    settings: {
      slidesToShow: 4
    }
  }, {
    breakpoint: 768,
    settings: {
      slidesToShow: 2
    }
  }]
};
export default function ListSlider({
  categories,
  content
}) {
  _s();
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return <section className="list_and_slider_section">
      <div className="container">
        <div className="list_box">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="list_card">
                <div className="text_box">
                  <h2 className="title">{content.list_one_title}</h2>
                  <p className="pera">{content.list_one_description}</p>
                </div>

                <div className="imgbox">
                  <img src={createPublicUrl(content.list_one_image) ? createPublicUrl(content.list_one_image) : "/image/no-image.svg"} alt="" />

                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="list_card">
                <div className="text_box">
                  <h2 className="title">{content.list_two_title}</h2>
                  <p className="pera">{content.list_two_description}</p>
                </div>

                <div className="imgbox">
                  <img src={createPublicUrl(content.list_two_image) ? createPublicUrl(content.list_two_image) : "/image/no-image.svg"} alt="" />

                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="list_card">
                <div className="text_box">
                  <h2 className="title">{content.list_three_title}</h2>
                  <p className="pera">{content.list_three_description}</p>
                </div>

                <div className="imgbox">
                  <img src={createPublicUrl(content.list_three_image) ? createPublicUrl(content.list_three_image) : "/image/no-image.svg"} alt="" />

                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="list_card">
                <div className="text_box">
                  <h2 className="title">{content.list_four_title}</h2>
                  <p className="pera">{content.list_four_description}</p>
                </div>

                <div className="imgbox">
                  <img src={createPublicUrl(content.list_four_image) ? createPublicUrl(content.list_four_image) : "/image/no-image.svg"} alt="" />

                </div>
              </div>
            </div>
          </div>
        </div>

        {isClient && categories ? <div className="slider_box">
            <h2 className="title">{content.slider_title}</h2>

            <Slider className="need_slider" {...sliderSettings}>
              {categories.map((category, index) => <div className="swiper-slide" key={index}>
                  <div className="need_card">
                    <div className="head">
                      <div className="imgbox">
                        <img src={category.image ? createPublicUrl(category.image) : "image/no-image.svg"} alt="" />

                      </div>
                    </div>

                    <div className="body">
                      <h3 className="title">{category.name}</h3>
                    </div>
                  </div>
                </div>)}
            </Slider>
          </div> : null}
      </div>
    </section>;
}
_s(ListSlider, "k460N28PNzD7zo1YW47Q9UigQis=");
_c = ListSlider;
var _c;
$RefreshReg$(_c, "ListSlider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;